import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { User } from 'app/core/user/user.types';
import { FetchCurrentUserGQL } from 'graphql/generated';
import { map, Observable, of, ReplaySubject, switchMap, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class UserService {
    private _httpClient = inject(HttpClient);
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private fetchCurrentUserGQL = inject(FetchCurrentUserGQL);
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(): Observable<any> {
        return this.fetchCurrentUserGQL.fetch().pipe(
            map(result => {
              this._user.next(result.data.fetchCurrentUser as any);
              this.user = result.data.fetchCurrentUser as any;
              return result.data.fetchCurrentUser;
            })
          );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User) {
        this._user.next(user);
    }
}
