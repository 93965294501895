import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { LoginGQL, LoginInput, Session, User, UserRole } from 'graphql/generated';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';

export enum AuthConstant {
    tokenLocalName = "token",
    sessionLocalName = "userSession"
  }

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    private _httpClient = inject(HttpClient);
    private _userService = inject(UserService);
    private loginGQL = inject(LoginGQL);
    private router = inject(Router);
    // private login = inject(LoginGQL)

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { username: string; password: string }): Observable<any> {
        return this.loginGQL.fetch({ loginInput: credentials }, { fetchPolicy: 'no-cache' }).pipe(
            switchMap(result => {
                const session = result.data.login;
                this._authenticated = true;
                return of(session);
            })
        );
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post('api/auth/sign-in', credentials).pipe(
            switchMap((response: any) => {
                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    login(payload: LoginInput): Promise<Session> {
        return new Promise((resolve, reject) => {
          this.loginGQL.fetch({ loginInput: payload }).subscribe(
            result => {
              if(result.errors) {

                reject(result.errors);
              }
              const session = result.data?.login as Session;
              this.finalizeAuthentification(session);
              resolve(session as Session);
            },
            error => {
              reject(error);
            }
          )
        })
      }

      finalizeAuthentification(session: Session) {
        this.saveToken(session.token);
        this.saveSession(session);
        this._userService.user = session.user as any;
        this._userService.update(session.user as any)
        switch(session.user.role) {
          case UserRole.Admin:
            this.router.navigate(['/reportings']);
            break;
          case UserRole.Collaborator:
            this.router.navigate(['/visites']);
            break;
          default:
            this.router.navigate(['/visits-control']);
        }
      }

      saveToken(token: string) {
        localStorage.setItem(AuthConstant.tokenLocalName, token);
      }

      getToken() {
        return localStorage.getItem(AuthConstant.tokenLocalName);
      }

      saveSession(session: Session) {
        localStorage.setItem(AuthConstant.sessionLocalName, JSON.stringify(session));
      }

      getSession() {
        return localStorage.getItem(AuthConstant.sessionLocalName);
      }

      getSessionAsObject() {
        const session = localStorage.getItem(AuthConstant.sessionLocalName);
        if(session) {
          return JSON.parse(session);
        }
        return null;
      }

      getCurrentUser() {
        const session = this.getSessionAsObject();
        if(session) {
          return session.user;
        }
        return null;
      }

      isLogedIn() {
        return Boolean(this.getSession());
      }

      logout() {
        this.cleanAuthData();
        this.router.navigate([''])
      }

      cleanAuthData() {
        localStorage.clear();
      }

      requestPasswordReset(email: string) {
      //   return this.requestPasswordResetGQL.mutate({ email });
      }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient
            .post('api/auth/sign-in-with-token', {
                accessToken: this.accessToken,
            })
            .pipe(
                catchError(() =>
                    // Return false
                    of(false)
                ),
                switchMap((response: any) => {
                    // Replace the access token with the new one if it's available on
                    // the response object.
                    //
                    // This is an added optional step for better security. Once you sign
                    // in using the token, you should generate a new one on the server
                    // side and attach it to the response object. Then the following
                    // piece of code can replace the token with the refreshed one.
                    if (response.accessToken) {
                        this.accessToken = response.accessToken;
                    }

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response.user;

                    // Return true
                    return of(true);
                })
            );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        name: string;
        email: string;
        password: string;
        company: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
